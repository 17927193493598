@tailwind base;
@tailwind components;
@tailwind utilities;
#root {
    font-family: 'Poppins', sans-serif;
}
.boxes_otp{
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
  }
  .input_otp{
    background-color: #d9e7fd;
    width: 50px;
    aspect-ratio: 1/1;
    border-radius: 10px;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  .forgot_btn{
    margin: 5px;
    /* height: 50px; */
  }
  .timer{
    margin: 20px 0px -10px;
  }
  .forgot_resend{
    background: none;
    /* color: rgba(255, 255, 255, 0.507); */
    text-decoration: none;
  
  }
  .forgot_resend:hover{
    color: #d9e7fd;
  }

  .loader {
    display:inline-block;
    border: 5px solid white;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  .hamburgerMenu{
    display: none;
  }

  .backArrow{
    display: flex;
  }


  @media (max-width: 650px){
    .hamburgerMenu{
      display: flex;
    }
    /* .backArrow{
      display: none;
    }
    .chatHistory{
      display: none;
    } */
    .bookMyAppointment, .nextAppointment, .chatHistory, .backArrow, .symptomTracker{
      display: none;
    }
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }